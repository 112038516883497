import { Helmet } from 'react-helmet-async';
import { Container } from '../components/Container';
import { FadeIn } from '../components/FadeIn';

export default function Terms() {
  return (
    <>
      <Helmet>
        <title>Kullanım Koşulları | Codenra</title>
        <meta name="description" content="Codenra web sitesi ve hizmetleri kullanım koşulları. Lütfen hizmetlerimizi kullanmadan önce okuyunuz." />
      </Helmet>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <div className="max-w-5xl">
            <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
              Kullanım Koşulları
            </h1>
            <div className="mt-6 text-xl text-neutral-600">
              <p className="mb-8">
                Codenra Teknoloji ve Bilişim Ltd. Şti. web sitesi kullanım koşulları ve şartları.
              </p>
            </div>
          </div>

          <div className="mt-24 space-y-12 text-neutral-600">
            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                1. Genel Hükümler
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Bu web sitesini kullanarak, bu kullanım koşullarını kabul etmiş sayılırsınız. Bu koşulları kabul etmiyorsanız, lütfen siteyi kullanmayınız.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                2. Hizmet Kullanım Şartları
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Web sitemiz üzerinden sunulan hizmetler, aşağıdaki koşullara tabidir:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Hizmetlerimizi yasal ve etik kurallara uygun şekilde kullanmayı kabul edersiniz</li>
                  <li>Sistemlerimize zarar verecek herhangi bir faaliyette bulunmamayı taahhüt edersiniz</li>
                  <li>Hizmetlerimizi kötüye kullanmamayı kabul edersiniz</li>
                  <li>Diğer kullanıcıların haklarına saygı göstermeyi kabul edersiniz</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                3. Fikri Mülkiyet Hakları
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Web sitemizdeki tüm içerik (metin, görsel, logo, tasarım vb.) Codenra Teknoloji ve Bilişim Ltd. Şti.'nin fikri mülkiyeti altındadır ve ilgili telif hakkı kanunları ile korunmaktadır.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                4. Sorumluluk Reddi
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Web sitemizdeki bilgiler "olduğu gibi" sunulmaktadır. Site içeriğinin doğruluğu, güncelliği ve kullanımından doğabilecek zararlardan sorumlu değiliz.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                5. Değişiklikler
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Bu kullanım koşullarını herhangi bir zamanda değiştirme hakkını saklı tutarız. Değişiklikler web sitemizde yayınlandığı anda yürürlüğe girer.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                İletişim
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Bu kullanım koşulları ile ilgili sorularınız için iletisim@codenra.com.tr adresine e-posta gönderebilirsiniz.
                </p>
              </div>
            </section>
          </div>
        </FadeIn>
      </Container>
    </>
  );
} 