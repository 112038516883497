import { Border } from "../components/Border";
import { ContactSection } from "../components/ContactSection";
import { Container } from "../components/Container";
import { FadeIn, FadeInStagger } from "../components/FadeIn";
import { GridList, GridListItem } from "../components/GridList";
import { PageIntro } from "../components/PageIntro";
import { PageLinks } from "../components/PageLinks";
import { SectionIntro } from "../components/SectionIntro";
import { StatList, StatListItem } from "../components/StatList";
import { team } from "../data";
import { Helmet } from 'react-helmet-async';

function Culture() {
  return (
    <div className="mt-24 rounded-4xl bg-neutral-950 py-24 sm:mt-32 lg:mt-40 lg:py-32">
      <SectionIntro
        eyebrow="Geliştirme Sürecimiz"
        title="Nasıl Çalışıyoruz?"
        invert
      >
        <p>
          Codenra ile çalışmaya başlayın; ihtiyaç duyduğunuz her şeyi sağlayarak
          farkındalık yaratmanıza, trafik çekmenize ve bağlantılar kurmanızı
          sağlarız.
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <GridList>
          <GridListItem title="Analiz ve Planlama" invert>
            Sektör analizi yapılarak, projeniz için talep ve beklentilerinize
            uygun bir iş planı hazırlanır. Bu aşamada, projenizin temel
            gereksinimleri ve stratejik hedefleri belirlenir.
          </GridListItem>

          <GridListItem title="Tasarım ve Kodlama" invert>
            Projenizin ihtiyaçlarına yönelik tasarım hazırlanır ve ardından
            yazılım kodlaması gerçekleştirilir. Bu süreç, estetik ve
            işlevselliği bir araya getirerek yazılımın tamamlanmasını sağlar.
          </GridListItem>

          <GridListItem title="Test ve Kontrol" invert>
            Proje tamamlandıktan sonra test edilir ve varsa hatalar ile
            eksiklikler tespit edilip düzeltilir. Bu aşamada, yazılımın
            güvenilirliği ve performansı garanti altına alınır.{" "}
          </GridListItem>
        </GridList>
      </Container>
    </div>
  );
}

function Team() {
  return (
    <Container className="mt-24 sm:mt-32 lg:mt-40">
      <div className="space-y-24">
        {team.map((group) => (
          <FadeInStagger key={group.title}>
            <Border as={FadeIn} />
            <div className="grid grid-cols-1 gap-6 pt-12 sm:pt-16 lg:grid-cols-4 xl:gap-8">
              <FadeIn>
                <h2 className="font-display text-2xl font-semibold text-neutral-950">
                  {group.title}
                </h2>
              </FadeIn>
              <div className="lg:col-span-3">
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-8"
                >
                  {group.people.map((person) => (
                    <li key={person.name}>
                      <FadeIn>
                        <div className="group relative overflow-hidden rounded-3xl bg-neutral-100">
                          <img
                            alt=""
                            {...person.image}
                            className="h-96 w-full object-cover grayscale transition duration-500 motion-safe:group-hover:scale-105"
                          />
                          <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black to-black/0 to-40% p-6">
                            <p className="font-display text-base/6 font-semibold tracking-wide text-white">
                              {person.name}
                            </p>
                            <p className="mt-2 text-sm text-white">
                              {person.role}
                            </p>
                          </div>
                        </div>
                      </FadeIn>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </FadeInStagger>
        ))}
      </div>
    </Container>
  );
}

export const metadata = {
  title: "About Us",
  description:
    "We believe that our strength lies in our collaborative approach, which puts our clients at the center of everything we do.",
};

export default function About() {
  return (
    <>
      <Helmet>
        <title>Kurumsal | Codenra</title>
        <meta name="description" content="15+ yıllık deneyimimiz ve uzman ekibimizle teknoloji çözümleri üretiyoruz. Codenra'yı daha yakından tanıyın." />
      </Helmet>

      <PageIntro eyebrow="Kurumsal" title="Teknoloji ile Geleceği Şekillendiriyoruz">
        <p>
          Codenra olarak inovasyonu ve güçlü iş ortaklıklarını merkeze alıyoruz.
          <b className="text-black"> Müşterilerimizi dinliyor</b>, ihtiyaçlarını derinlemesine analiz
          ediyor ve özel çözümler geliştiriyoruz.
          Her projemiz, yalnızca bugünün değil, geleceğin ihtiyaçlarına da yanıt verecek şekilde tasarlanıyor.
        </p>

        <div className="mt-10 space-y-6 text-base">
          <p>
            Programlama ve yazılım geliştirme süreçlerimizde esnekliği ve sürdürülebilirliği ön planda tutuyoruz.
            Çözümlerimiz, hem yazılım hem de donanım ihtiyaçlarını kapsayacak şekilde geliştiriliyor.
            Optimize edilmiş veri akışları, hafif servis mimarileri ve bulut tabanlı çözümler ile maliyetleri düşürürken verimliliği artıran yaklaşımlar sunuyoruz.
          </p>
          <p>
            Yenilikçi yaklaşımlarımızı yapay zeka ile birleştirerek işlerimizi daha hızlı ve verimli hale getiriyoruz.
            Yapay zekayı yalnızca bir araç olarak değil, projelerimizin her aşamasında bir çözüm ortağı olarak konumlandırıyoruz.
            Veri analizi ile pazar trendlerini öngörmek, dil işleme algoritmalarıyla metin analizleri yapmak ve görüntü işleme teknikleriyle kalite kontrol süreçlerini optimize etmek gibi somut uygulamalarla projelerimize değer katıyoruz.
          </p>
          <p>
            Bizim için her proje bir taahhüt anlamına gelir. Özenle çalışıyor, detaylara önem veriyor ve teslim sürelerimize her zaman sadık kalıyoruz.
            Müşterilerimizle düzenli iletişim ve şeffaf süreç yönetimi sağlayarak uzun vadeli bir iş ortaklığı kuruyoruz. Gereksinimleri sürekli olarak gözden geçiriyor ve ihtiyaçlara hızla adapte olabilen çözümler geliştiriyoruz.
            Aynı zamanda donanımsal entegrasyonlar ve sistem çözümleriyle iş süreçlerinizi destekliyoruz.
          </p>
        </div>
      </PageIntro>


      <Container className="mt-16">
        <StatList>
          <StatListItem value="15+" label="Yıl Deneyim" />
          <StatListItem value="10.000+" label="Kullanıcı" />
          <StatListItem value="80+" label="Tamamlanmış Proje" />
        </StatList>
      </Container>

      <Culture />

      <Team />

      <ContactSection />
    </>
  );
}
