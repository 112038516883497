import { Blockquote } from "../components/Blockquote";
import { ContactSection } from "../components/ContactSection";
import { Container } from "../components/Container";
import { FadeIn } from "../components/FadeIn";
import { GridList, GridListItem } from "../components/GridList";
import { GridPattern } from "../components/GridPattern";
import { List, ListItem } from "../components/List";
import { PageIntro } from "../components/PageIntro";
import { SectionIntro } from "../components/SectionIntro";
import { StylizedImage } from "../components/StylizedImage";
import { TagList, TagListItem } from "../components/TagList";
import imageLaptop from "../images/laptop.jpg";
import imageMeeting from "../images/meeting.jpg";
import imageWhiteboard from "../images/whiteboard.jpg";
import { Helmet } from 'react-helmet-async';

function Section({ title, image, children }) {
  return (
    <Container className="group/section [counter-increment:section]">
      <div className="lg:flex lg:items-center lg:justify-end lg:gap-x-8 lg:group-even/section:justify-start xl:gap-x-20">
        <div className="flex justify-center">
          <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
            <StylizedImage
              {...image}
              sizes="(min-width: 1024px) 41rem, 31rem"
              className="justify-center lg:justify-end lg:group-even/section:justify-start"
            />
          </FadeIn>
        </div>
        <div className="mt-12 lg:mt-0 lg:w-[37rem] lg:flex-none lg:group-even/section:order-first">
          <FadeIn>
            <div
              className="font-display text-base font-semibold before:text-neutral-300 before:content-['/_'] after:text-neutral-950 after:content-[counter(section,decimal-leading-zero)]"
              aria-hidden="true"
            />
            <h2 className="mt-2 font-display text-3xl font-medium tracking-tight text-neutral-950 sm:text-4xl">
              {title}
            </h2>
            <div className="mt-6">{children}</div>
          </FadeIn>
        </div>
      </div>
    </Container>
  );
}

function Discover() {
  return (
    <Section title="Analiz ve Planlama" image={{ src: imageWhiteboard }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
          Bu aşamada, projenizin başarılı bir şekilde hayata geçirilmesi için
          kapsamlı bir sektör analizi gerçekleştirilir. İhtiyaç ve
          beklentilerinize uygun olarak detaylı bir iş planı hazırlanır. Analiz,
          mevcut pazar koşullarını, hedef kitlenizi ve rekabeti değerlendirerek
          stratejik hedeflerinizi belirlemeye yardımcı olur. Bu süreç,
          projenizin yönünü netleştirir ve kaynakların etkin bir şekilde
          kullanılmasını sağlar.
        </p>
      </div>

      {/*<h3 className="mt-12 font-display text-base font-semibold text-neutral-950">
        Included in this phase
      </h3>
      <TagList className="mt-4">
        <TagListItem>In-depth questionnaires</TagListItem>
        <TagListItem>Feasibility studies</TagListItem>
        <TagListItem>Blood samples</TagListItem>
        <TagListItem>Employee surveys</TagListItem>
        <TagListItem>Proofs-of-concept</TagListItem>
        <TagListItem>Forensic audit</TagListItem>
      </TagList>*/}
    </Section>
  );
}

function Build() {
  return (
    <Section title="Tasarım ve Kodlama" image={{ src: imageLaptop, shape: 1 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
          Projenizin estetik ve işlevsel gereksinimlerini karşılamak için
          kullanıcı arayüzü (UI) ve kullanıcı deneyimi (UX) tasarımları, hedef
          kitlenizin beklentilerine uygun olarak detaylı bir analiz süreciyle
          oluşturulur. Wireframe ve prototip aşamalarından geçerek, tasarımın
          her yönü titizlikle planlanır. Ardından, bu tasarımlar modern yazılım
          standartlarına uygun bir şekilde kodlanır.
        </p>

        <p>
          Kodlama sürecinde, sürdürülebilirlik, güvenlik ve performans odaklı
          bir yaklaşım benimsenir. Responsive tasarım ve modüler kod yapıları
          gibi en iyi uygulamalar hayata geçirilir. Ayrıca, versiyon kontrol
          sistemleri (Git) ve CI/CD (Continuous Integration/Continuous
          Deployment) süreçleri kullanılarak, kod kalitesi sürekli olarak
          izlenir ve geliştirilir. Bu titiz süreç, projenizin hem görsel olarak
          çarpıcı hem de kullanıcı dostu olmasını sağlar.
        </p>
      </div>

      {/*<Blockquote
        author={{ name: "Debra Fiscal", role: "CEO of Unseal" }}
        className="mt-12"
      >
        Studio were so regular with their progress updates we almost began to
        think they were automated!
      </Blockquote>*/}
    </Section>
  );
}

function Deliver() {
  return (
    <Section title="Test ve Kontrol" image={{ src: imageMeeting, shape: 2 }}>
      <div className="space-y-6 text-base text-neutral-600">
        <p>
          Yazılım geliştirme süreci tamamlandıktan sonra, kapsamlı bir test
          aşamasına geçilir. Bu aşamada, yazılımın tüm fonksiyonları hem manuel
          hem de otomatik testler kullanılarak incelenir. Fonksiyonel testler,
          yazılımın belirlenen gereksinimlere uygun çalışıp çalışmadığını
          kontrol ederken, performans testleri uygulamanın farklı yük
          seviyelerinde nasıl davrandığını değerlendirir. Güvenlik testleri,
          potansiyel güvenlik açıklarını tespit etmek için yapılır. Kullanıcı
          deneyimi testleri ise, gerçek kullanıcı senaryolarında uygulamanın
          kullanılabilirliğini ölçer.
        </p>

        <p>
          Bu süreçte, A/B testi gibi yöntemlerle farklı versiyonlar
          karşılaştırılarak en uygun çözüm belirlenir. Ayrıca, QA (Quality
          Assurance) süreci kapsamında, yazılımın belirlenen kalite
          standartlarına uygunluğu doğrulanır. Tespit edilen hatalar ve
          eksiklikler düzeltilir, gerekli optimizasyonlar gerçekleştirilir. Bu
          titiz süreç, yazılımın en yüksek kalite standartlarına ulaşmasını ve
          kullanıcılar için sorunsuz bir deneyim sunmasını garanti eder.
        </p>
      </div>

      {/*<h3 className="mt-12 font-display text-base font-semibold text-neutral-950">
        Included in this phase
      </h3>
      <List className="mt-8">
        <ListItem title="Testing">
          Our projects always have 100% test coverage, which would be impressive
          if our tests weren’t as porous as a sieve.
        </ListItem>
        <ListItem title="Infrastructure">
          To ensure reliability we only use the best Digital Ocean droplets that
          $4 a month can buy.
        </ListItem>
        <ListItem title="Support">
          Because we hold the API keys for every critical service your business
          uses, you can expect a lifetime of support, and invoices, from us.
        </ListItem>
      </List>*/}
    </Section>
  );
}

function Values() {
  return (
    <div className="relative mt-24 pt-24 sm:mt-32 sm:pt-32 lg:mt-40 lg:pt-40">
      <div className="absolute inset-x-0 top-0 -z-10 h-[884px] overflow-hidden rounded-t-4xl bg-gradient-to-b from-neutral-50">
        <GridPattern
          className="absolute inset-0 h-full w-full fill-neutral-100 stroke-neutral-950/5 [mask-image:linear-gradient(to_bottom_left,white_40%,transparent_50%)]"
          yOffset={-270}
        />
      </div>

      <SectionIntro
        eyebrow=""
        title="En Güncel Teknolojiyle Geliştirilmiş Çözümler"
      />

      <Container className="mt-24">
        <GridList>
          <GridListItem title="Proje Konusu">
            Sorunun tanımlanması ve uygulanacak çözümün ana hatlarının
            belirlenmesi aşamasıdır. Bu aşamada, çözümün hedeflenecek problemi
            net bir şekilde tanımlıyoruz.
          </GridListItem>

          <GridListItem title="Problem Çözümü">
            Sorunun çözümü için en uygun ortamların belirlenmesi yapılır. Bu
            aşamada, problemi en kolay şekilde çözmek için gerekli hesaplamalar
            ve analizler gerçekleştirilir.
          </GridListItem>

          <GridListItem title="Gereken Teknolojiler">
            Enerji verimli ve etkili yöntemlerle kullanılacak en iyi
            teknolojilere karar verilir. Bu aşamada, projede kullanılacak en
            uygun araç ve yöntemler seçilir.
          </GridListItem>

          <GridListItem title="UI & UX">
            Tasarımcılar ve yazılım geliştiriciler, uygulamanın en iyi nasıl
            kullanılacağını belirlemek için ortak çalışır. Kullanıcı deneyimi ve
            arayüz tasarımı bu aşamada şekillendirilir.
          </GridListItem>

          <GridListItem title="Backend">
            Bilgilerin saklanacağı ve hizmetlerin çalışacağı sunucu test ve
            üretim aşamaları için yayımlanır. Bu aşamada, arka uç sistemler
            kurulur ve test edilir.
          </GridListItem>

          <GridListItem title="Frontend">
            Projede yapılan tüm çalışmalar kullanıcı ile buluşur. Bu aşamada,
            proje tüm ortamlarda test edilir ve sürümler halinde yayımlanır.
          </GridListItem>
        </GridList>
      </Container>
    </div>
  );
}

export const metadata = {
  title: "Our Process",
  description:
    "We believe in efficiency and maximizing our resources to provide the best value to our clients.",
};

export default function Process() {
  return (
    <>
      <Helmet>
        <title>Geliştirme Süreci | Codenra</title>
        <meta name="description" content="Projelerinizi nasıl hayata geçiriyoruz? Codenra'nın profesyonel yazılım geliştirme sürecini keşfedin." />
      </Helmet>

      <PageIntro eyebrow="Süreç" title="Geliştirme Sürecimiz">
        <p>
          Müşterilerimize en güncel teknoloji ile geliştirilmiş,{" "}
          <b className="text-black">tasarım harikası</b> olarak tanımladığımız
          çözümler sunmak için profesyonel geliştirme ekibimiz ile çalışıyoruz
        </p>
      </PageIntro>

      <div className="mt-24 space-y-24 [counter-reset:section] sm:mt-32 sm:space-y-32 lg:mt-40 lg:space-y-40">
        <Discover />
        <Build />
        <Deliver />
      </div>

      <Values />

      <ContactSection />
    </>
  );
}
