import clsx from "clsx";

export const socialMediaProfiles = [
  { title: "GitHub", to: "https://github.com/codenra" },
  {
    title: "Linkedin",
    to: "https://www.linkedin.com/company/codenra",
  },
  {
    title: "X",
    to: "https://x.com/codenra",
  },
  {
    title: "Instagram",
    to: "https://instagram.com/codenratr",
  },
  {
    title: "Facebook",
    to: "https://www.facebook.com/codenra/",
  },
];

export function SocialMedia({ className, invert = false }) {
  return (
    <ul
      role="list"
      className={clsx(
        "flex gap-x-10",
        invert ? "text-white" : "text-neutral-950",
        className
      )}
    >
      {socialMediaProfiles.map((socialMediaProfile) => (
        <li key={socialMediaProfile.title}>
          <a
            target="_blank"
            href={socialMediaProfile.to}
            aria-label={socialMediaProfile.title}
            className={clsx(
              "transition",
              invert ? "hover:text-neutral-200" : "hover:text-neutral-700"
            )}
          >
            {socialMediaProfile.title}
          </a>
        </li>
      ))}
    </ul>
  );
}
