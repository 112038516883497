import { Link } from "react-router-dom";
import { Blockquote } from "../components/Blockquote";
import { Border } from "../components/Border";
import { Button } from "../components/Button";
import { ContactSection } from "../components/ContactSection";
import { Container } from "../components/Container";
import { FadeIn, FadeInStagger } from "../components/FadeIn";
import { PageIntro } from "../components/PageIntro";
import { Testimonial } from "../components/Testimonial";
import { formatDate } from "../lib/formatDate";
import { completedProjects } from "../data";
import { Clients } from "./Home";
import { SectionIntro } from "../components/SectionIntro";
import { Helmet } from 'react-helmet-async';

function CaseStudies({ caseStudies }) {
  return (
    <Container className="mt-40">
      <FadeIn>
        <h2 className="font-display text-2xl font-semibold text-neutral-950">
          Projeler
        </h2>
      </FadeIn>
      <div className="mt-10 space-y-20 sm:space-y-24 lg:space-y-32">
        {caseStudies.map((caseStudy) => (
          <FadeIn key={caseStudy.client}>
            <article>
              <Border className="grid grid-cols-3 gap-x-8 gap-y-8 pt-16">
                <div className="col-span-full sm:flex sm:items-center sm:justify-between sm:gap-x-8 lg:col-span-1 lg:block">
                  <div className="sm:flex sm:items-center sm:gap-x-6 lg:block">
                    <div className="bg-black w-28 h-28 rounded-full flex items-center justify-center">
                      <img
                        src={caseStudy.logo}
                        alt=""
                        className="w-20 flex-none object-cover"
                        unoptimized
                      />
                    </div>
                    <h3 className="mt-6 text-sm font-semibold text-neutral-950 sm:mt-0 lg:mt-8">
                      {caseStudy.client}
                    </h3>
                  </div>
                  <div className="mt-1 flex gap-x-4 sm:mt-0 lg:block">
                    <p className="text-sm tracking-tight text-neutral-950 after:ml-4 after:font-semibold after:text-neutral-300 after:content-['/'] lg:mt-2 lg:after:hidden">
                      {caseStudy.service}
                    </p>

                    {/*<p className="text-sm text-neutral-950 lg:mt-2">
                      <time dateTime={caseStudy.date}>
                        {formatDate(caseStudy.date)}
                      </time>
                    </p>*/}
                  </div>
                </div>
                <div className="col-span-full lg:col-span-2 lg:max-w-2xl">
                  <p className="font-display text-4xl font-medium text-neutral-950">
                    <Link to={caseStudy.href}>{caseStudy.title}</Link>
                  </p>
                  <div className="mt-6 space-y-6 text-base text-neutral-600">
                    {caseStudy.summary.map((paragraph) => (
                      <p key={paragraph}>{paragraph}</p>
                    ))}
                  </div>
                  <div className="mt-8 flex">
                    <Button
                      to={caseStudy.href}
                      aria-label={`Daha Fazla: ${caseStudy.client}`}
                    >
                      Daha Fazla
                    </Button>
                  </div>
                  {caseStudy.testimonial && (
                    <Blockquote
                      author={caseStudy.testimonial.author}
                      className="mt-12"
                    >
                      {caseStudy.testimonial.content}
                    </Blockquote>
                  )}
                </div>
              </Border>
            </article>
          </FadeIn>
        ))}
      </div>

      <Clients />
    </Container>
  );
}

/*function Clients() {
  return (
    <Container className="mt-24 sm:mt-32 lg:mt-40">
      <FadeIn>
        <h2 className="font-display text-2xl font-semibold text-neutral-950">
          You’re in good company
        </h2>
      </FadeIn>
      <FadeInStagger className="mt-10" faster>
        <Border as={FadeIn} />
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-8 gap-y-12 sm:grid-cols-3 lg:grid-cols-4"
        >
          {clients.map(([client, logo]) => (
            <li key={client} className="group">
              <FadeIn className="overflow-hidden">
                <Border className="pt-12 group-[&:nth-child(-n+2)]:-mt-px sm:group-[&:nth-child(3)]:-mt-px lg:group-[&:nth-child(4)]:-mt-px">
                  <img src={logo} alt={client} />
                </Border>
              </FadeIn>
            </li>
          ))}
        </ul>
      </FadeInStagger>
    </Container>
  );
}*/

export const metadata = {
  title: "Our Work",
  description:
    "We believe in efficiency and maximizing our resources to provide the best value to our clients.",
};

export default function Work() {
  return (
    <>
      <Helmet>
        <title>Çalışmalarımız | Codenra</title>
        <meta name="description" content="Başarıyla tamamladığımız projeler ve referanslarımız. Codenra'nın portföyünü inceleyin." />
      </Helmet>

      <PageIntro eyebrow="Çalışmalarımız" title="Başarıyla Tamamladığımız Projeler">
        {/* Rest of the component */}
      </PageIntro>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <div className="max-w-5xl">
            <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
              Yüksek Teknoloji ile Geliştirdiğimiz Başarılı Projeler
            </h1>
            <div className="mt-6 text-xl text-neutral-600">
              <p className="mb-8">
                En güncel teknolojileri kullanarak geliştirdiğimiz yazılım çözümleriyle, müşterilerimizin dijital dönüşüm yolculuğunda güvenilir bir iş ortağı olduk. Her bir projemiz, teknik mükemmellik ve inovasyon tutkumuzun bir yansıması olarak öne çıkıyor. Tamamladığımız projelerin sektörlerinde fark yaratmasından ve müşterilerimizin başarı hikayelerinin bir parçası olmaktan gurur duyuyoruz.
              </p>
            </div>
          </div>
        </FadeIn>

        <CaseStudies caseStudies={completedProjects} />

        {/*<Clients />*/}

        <ContactSection />
      </Container>
    </>
  );
}
