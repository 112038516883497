import clsx from "clsx";

export function Office({ name, children, invert = false }) {
  return (
    <address
      className={clsx(
        "text-sm not-italic",
        invert ? "text-neutral-300" : "text-neutral-600"
      )}
    >
      <strong className={invert ? "text-white" : "text-neutral-950"}>
        {name}
      </strong>
      <br />
      {children}
    </address>
  );
}

export function Offices({
  invert = false,
  useBlackForEmailAndTel = false,
  ...props
}) {
  return (
    <ul role="list" {...props}>
      <li>
        <Office name="Geliştirme Ofisi" invert={invert}>
          Feyzullah Mah. Şehit Hikmet Alp Cad. No:8/28 Maltepe / Istanbul / Türkiye
          <div className="my-2 mt-3">
            <a
              className={clsx(
                "font-semibold",
                useBlackForEmailAndTel ? "text-black" : "text-white"
              )}
              href="tel:+905547997414"
            >
              +90 (554) 799 74 14
            </a>
          </div>
          <div>
            <a
              className={clsx(
                "font-semibold",
                useBlackForEmailAndTel ? "text-black" : "text-white"
              )}
              href="mailto:iletisim@codenra.com.tr"
            >
              iletisim@codenra.com.tr
            </a>
          </div>
          <div className="mt-3">
            <a
              href="https://g.page/codenra?share"
              target="_blank"
              className="text-white"
            >
              Harita Linki
            </a>
          </div>
        </Office>
      </li>
      <li>
        <Office name="Resmi Ofis" invert={invert}>
          Barbaros Mah. Begonya Sok. Nida Kule No:1/2 Ataşehir / İstanbul /
          Türkiye
          <div className="my-2 mt-3">
            <a
              className={clsx(
                "font-semibold",
                useBlackForEmailAndTel ? "text-black" : "text-white"
              )}
              href="tel:+905547997414"
            >
              +90 (554) 799 74 14
            </a>
          </div>
          <div>
            <a
              className={clsx(
                "font-semibold",
                useBlackForEmailAndTel ? "text-black" : "text-white"
              )}
              href="mailto:info@codenra.com"
            >
              info@codenra.com
            </a>
          </div>
        </Office>
      </li>
      <li>
        <Office name="Londra" invert={invert}>
          C/54 Northwest Freeway, Suite 558, London, UK 485
          <div className="my-2 mt-3">
            <a
              className={clsx(
                "font-semibold",
                useBlackForEmailAndTel ? "text-black" : "text-white"
              )}
              href="tel:+447502390907"
            >
              +44 7502 390907
            </a>
          </div>
          <div>
            <a
              className={clsx(
                "font-semibold",
                useBlackForEmailAndTel ? "text-black" : "text-white"
              )}
              href="mailto:info@codenra.co.uk"
            >
              info@codenra.co.uk
            </a>
          </div>
        </Office>
      </li>
    </ul>
  );
}
