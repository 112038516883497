import YorkAndChapelLogo from "./images/clients/yorkandchapel.png";
import HaberTurkLogo from "./images/clients/haberturk.svg";
import KocSistemLogo from "./images/clients/kocsistem.svg";
import OcrLabsLogo from "./images/clients/ocrlabs.png";
import OmronLogo from "./images/clients/omron.svg";
import KukaLogo from "./images/clients/kuka.svg";
import IatseLogo from "./images/clients/IATSE-logo.png";
import GlitterMagazine from "./images/clients/glitter-logo.svg";
import DaisyWheelLogo from "./images/clients/daisy2.svg";
import ApifizLogo from "./images/apifizLogo.svg";
import AidsRehberiLogo from "./images/aidsRehberiLogo.svg";
import DemandLogo from "./images/demandLogo.svg";
import DemandScreenshot from "./images/demandScreenshot.png";
import ApifizScreenshot from "./images/apifizScreenshot.png";
import AidsRehberiScreenshot from "./images/aidsRehberiScreenshot.png";
import GlitterMagazineScreenshot from "./images/glitterMagazineScreenshot.png";
import IatseHeatStressScreenshot from "./images/iatseHeatStressScreenshot.png";
import DaisyWheelScreenshot from "./images/daisyWheelScreenshot.png";

import imageVolkanCatak from "./images/team/volkancatak.jpeg";
import imageGuneyUral from "./images/team/guneyural.jpeg";
import imageOzgurGaniTuncay from "./images/team/ozgurGaniTuncay.jpg";
import imageErayCaglarKuban from "./images/team/erayCaglarKuban.jpg";

export const clients = [
  { name: "York & Chapel", image: YorkAndChapelLogo },
  { name: "Haber Türk", image: HaberTurkLogo },
  { name: "Koç Sistem", image: KocSistemLogo },
  { name: "OCR Labs", image: OcrLabsLogo },
  { name: "Omron", image: OmronLogo },
  { name: "Kuka", image: KukaLogo },
];

export const completedProjects = [
  {
    work_name: "apifiz",
    client: "Apifiz",
    title: "Apifiz",
    description:
      "Apifiz, video konferans yoluyla belirli konularda uzmanlardan destek alabileceğiniz faydalı bir uygulamadır.",
    summary: [
      "Apifiz, video konferans yoluyla belirli konularda uzmanlardan destek alabileceğiniz faydalı bir uygulamadır.",
    ],
    logo: ApifizLogo,
    image: ApifizScreenshot,
    date: "",
    href: "/works/apifiz",
    overview:
      "Apifiz, randevu ile buluşması planlanan kişi veya grupların organizasyonunu kolay ve hızlı bir şekilde analiz eden bir uygulamadır. Apifiz kullanarak randevu aldığınız yer ile görüntülü, sesli ve yazılı olarak haberleşebilirsiniz. Geliştirme süreci devam eden Apifiz, size en kolay şekilde güvenli ve rahat bir iletişim ortamı sunar.\nKullanıcılar, Apifiz yardımıyla pek çok kategoride uzman moduna geçebilir ve eğitim alabilirler. Uzmanlar isteğe bağlı olarak canlı yayınlar açarak sunduğu hizmetler hakkında bilgi verebilir.",
    service: "Mobil Uygulama, Web Geliştirme / PWA",
    appStoreUrl: "https://apps.apple.com/tr/app/apifiz/id1520010266",
    websiteLink: "https://apifiz.com/",
  },
  {
    work_name: "demand",
    client: "Demand",
    title: "Demand",
    description:
      "Demand, restoran ve kafelerde barkod okutarak hizmetleri inceleyip sipariş verebileceğiniz veya garson çağırabileceğiniz bir PWA uygulamasıdır. Uygulama indirmenize gerek kalmadan, sadece barkodu okutarak interaktif bir deneyim sunar.",
    summary: [
      "Demand, restoran ve kafelerde barkod okutarak hizmetleri inceleyip sipariş verebileceğiniz veya garson çağırabileceğiniz bir PWA uygulamasıdır. Uygulama indirmenize gerek kalmadan, sadece barkodu okutarak interaktif bir deneyim sunar.",
    ],
    logo: DemandLogo,
    image: DemandScreenshot,
    date: "",
    href: "/works/demand",
    overview:
      "Demand temassız bir dünyaya için güncelleniyor. Aktif olarak hizmet veren firmaların rahatlıkla kullanabileceği ve özelleştirebileceği bir uygulamadır. Demand, işletmenizde hizmet veya ürün satın alınmasını sağlar. Bulunduğunuz yerdeki barkodu okutmanız ile işlem başlatılır ve servis sağlayıcınızın sunduğu bir platforma yönlendirilirsiniz. Bulunduğunuz panel size işletme ile kolayca iletişim kurma fırsatı verecek ve tüm isteklerinizin değerlendirildiğini online olarak izleyeceksiniz.\nİletişim cihazları arasında soket bağlantılar taleplerinizi anında gereken yere iletecektir. Demand PWA olarak tarayıcınızda çalışır ve sadece siz kullandığınız zaman aktif olacak bir uygulamadır. ",
    service: "Web Geliştirme / PWA",
    websiteLink: "https://demand.codenra.com/",
  },
  {
    work_name: "qa-platform",
    client: "Q/A Platform",
    title: "Q/A Platform",
    description:
      "Bu yazılım projesi, kullanıcıların belirli bir ücret karşılığında uzmanlardan ve diğer kullanıcılardan sorularına yanıt alabileceği bir soru/cevap platformudur. Platform, entegre ödeme altyapısı ile ücretli soru sorma hizmeti sunar.",
    summary: [
      "Bu yazılım projesi, kullanıcıların belirli bir ücret karşılığında uzmanlardan ve diğer kullanıcılardan sorularına yanıt alabileceği bir soru/cevap platformudur. Platform, entegre ödeme altyapısı ile ücretli soru sorma hizmeti sunar.",
    ],
    logo: AidsRehberiLogo,
    image: AidsRehberiScreenshot,
    date: "",
    href: "/works/qa-platform",
    overview:
      "Bu platform, ödeme alt yapıları kullanarak kullanıcıların belirli zaman aralıklarında döngüsel faturalarla ödeme yapmasını sağlar. Bu sayede kullanıcılar, belirli bir süre boyunca sorularını sorma hakkına sahip olurlar.\nAyrıca, platform sahibi tarafından Telegram veya diğer iletişim programları aracılığıyla siteden bağımsız olarak kontrol edilebilir. Bu özellik sayesinde, platform sahibi soruları yanıtlayan uzmanları ve kullanıcıları takip edebilir ve yönetebilir.\nGoogle AMP gibi projelerle entegrasyon sağlayan bu yazılım projesi, arama sonuçlarında da başarılı bir sonuç elde eder. Bu sayede, kullanıcılar sorularını sormak için bu platformu arama motorlarında bulabilirler.\nGenel olarak, bu soru / cevap yazılım projesi, ücretli bir yöntemle soru sormak isteyen kullanıcılar için ideal bir çözümdür. Ödeme alt yapıları, siteden bağımsız kontrol ve arama sonuçlarında başarılı sonuçlar gibi özellikleri sayesinde, kullanıcılar için en iyi deneyimi sunmayı hedeflemektedir.",
    service: "Web Geliştirme / PWA",
    websiteLink: "https://aidsrehberi.com/",
  },
  {
    work_name: "glitter-magazine",
    client: "Glitter Magazine",
    title: "Glitter Magazine",
    description:
      "Glitter Magazine, Amerika Birleşik Devletleri merkezli moda ve yaşam tarzı üzerine odaklanan, en son trendleri ve ünlülerle yapılan röportajları içeren bir dergidir.",
    summary: [
      "Glitter Magazine, Amerika Birleşik Devletleri merkezli moda ve yaşam tarzı üzerine odaklanan, en son trendleri ve ünlülerle yapılan röportajları içeren bir dergidir.",
    ],
    logo: GlitterMagazine,
    image: GlitterMagazineScreenshot,
    date: "",
    href: "/works/glitter-magazine",
    overview:
      "Glitter Magazine’nin web sitesi, prestijli bir yayın için gereken yüksek standartları karşılamak amacıyla, modern web teknolojileri ve en iyi yazılım geliştirme pratikleri kullanılarak geliştirilmiştir. Kullanıcı dostu bir arayüz ve yüksek performanslı bir altyapı ile ziyaretçilere hem estetik hem de işlevsel bir deneyim sunulmuştur. Responsive tasarım ve optimize edilmiş kod yapısı sayesinde, kullanıcıların her cihazda içeriğe hızlı ve etkili bir şekilde erişimi sağlanmıştır. Ayrıca, güvenlik protokollerine ve veri koruma standartlarına tam uyum gösterilerek, kullanıcı bilgilerinin en üst düzeyde korunması garanti altına alınmıştır. Bu proje, teknolojik yetkinliğimizin ve sürdürülebilir yazılım çözümleri sunma konusundaki kararlılığımızın bir yansımasıdır.",
    service: "Web Geliştirme / PWA",
    websiteLink: "https://glittermagazine.co/",
  },
  {
    work_name: "iatse891-heat-stress",
    client: "IATSE 891 Heat Stress",
    title: "IATSE 891 Heat Stress",
    description:
      "Bu uygulama, WorkSafeBC’nin kabul ettiği Humidex Yöntemi’ni kullanarak IATSE 891 üyeleri için ısı stresi riskini değerlendirir. Çalışanların ısı stresi risklerini anlamalarına ve azaltma önlemleri almalarına yardımcı olurken, üretim şirketleri ve işverenler için de uygunluk gereksinimlerini karşılamaya yönelik kullanılabilir.",
    summary: [
      "Bu uygulama, WorkSafeBC’nin kabul ettiği Humidex Yöntemi’ni kullanarak IATSE 891 üyeleri için ısı stresi riskini değerlendirir. Çalışanların ısı stresi risklerini anlamalarına ve azaltma önlemleri almalarına yardımcı olurken, üretim şirketleri ve işverenler için de uygunluk gereksinimlerini karşılamaya yönelik kullanılabilir.",
    ],
    logo: IatseLogo,
    image: IatseHeatStressScreenshot,
    date: "",
    href: "/works/iatse891-heat-stress",
    overview:
      "Bu uygulama, WorkSafeBC tarafından kabul edilen yöntemlerden birini kullanarak IATSE 891 üyeleri için ısı stresi riskini değerlendirir: G7.29-4 Humidex Yöntemi. Bu uygulama, çalışanların ısı stresi risklerini ve bunu azaltmak için kullanılabilecek önlemleri anlamalarına yardımcı olmayı amaçlamaktadır; aynı zamanda üretim şirketleri ve diğer işverenler tarafından uygunluk gereksinimlerini karşılamak için de kullanılabilir.",
    service: "Mobil Uygulama",
    appStoreUrl:
      "https://apps.apple.com/ca/app/iatse-891-heat-stress/id6447213326",
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=com.aura.iatse891",
  },
  {
    work_name: "daisy-wheel",
    client: "Daisy Wheel",
    title: "Daisy Wheel",
    description:
      "Daisy Wheel Uygulaması, kullanıcıların göğüs sağlığını takip etmelerine yardımcı olur. Göğüs kendi kendine muayenesini (BSE) 8 adımda öğrenmenizi sağlar ve düzenli hatırlatmalar ayarlamanıza olanak tanır. Bu sayede, vücudunuzu tanıyarak sağlıklı olanı fark etmenizi destekler.",
    summary: [
      "Daisy Wheel Uygulaması, kullanıcıların göğüs sağlığını takip etmelerine yardımcı olur. Göğüs kendi kendine muayenesini (BSE) 8 adımda öğrenmenizi sağlar ve düzenli hatırlatmalar ayarlamanıza olanak tanır. Bu sayede, vücudunuzu tanıyarak sağlıklı olanı fark etmenizi destekler.",
    ],
    logo: DaisyWheelLogo,
    image: DaisyWheelScreenshot,
    date: "",
    href: "/works/daisy-wheel",
    overview:
      "Daisy Wheel uygulaması, göğüs sağlığını koruma ve farkındalık yaratma amacıyla geliştirilmiştir. Bu uygulama, kullanıcılara göğüs kendi kendine muayenesini (BSE) adım adım öğretir ve düzenli olarak hatırlatmalar kurmalarını sağlar. Kullanıcı dostu bir arayüz ve modern yazılım teknikleriyle geliştirilen Daisy Wheel, hem estetik hem de işlevsellik açısından en iyi deneyimi sunar. Kullanıcıların bedenlerini tanımalarına ve normal olanı öğrenmelerine yardımcı olur.\nUygulama, güvenlik standartlarına ve veri koruma protokollerine tam uyum sağlayarak, kullanıcı bilgilerinin en üst düzeyde korunmasını garanti eder. Daisy Wheel, yazılım geliştirme konusundaki uzmanlığımızın bir göstergesi olarak, sağlık alanında fark yaratan çözümler üretme kararlılığımızı yansıtır.",
    service: "Mobil Uygulama",
    appStoreUrl:
      "https://apps.apple.com/us/app/daisy-wheel/id1461447061?platform=iphone",
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=com.git.daisywheel&hl=en",
  },
];

export const team = [
  {
    title: "Takım",
    people: [
      {
        name: "K. Volkan Çatak",
        role: "Full-Stack Developer",
        image: { src: imageVolkanCatak },
      },
      {
        name: "Güney Ural",
        role: "Full-Stack Developer",
        image: { src: imageGuneyUral },
      },
      {
        name: "Özgür Gani Tuncay",
        role: "Robotic Developer",
        image: { src: imageOzgurGaniTuncay },
      },
      {
        name: "Eray Çağlar Kuban",
        role: "Customer Relations",
        image: { src: imageErayCaglarKuban },
      },
    ],
  },
];

export const servicesData = [
  {
    title: "Web Geliştirme",
    slug: "web-development",
    description: "Modern web teknolojileri ile kullanıcı dostu, performanslı ve ölçeklenebilir web uygulamaları geliştiriyoruz.",
    features: [
      {
        title: "Özellikler ve Avantajlar",
        description: "Web uygulamalarımızda sunduğumuz temel özellikler:",
        items: [
          "Responsive tasarım - tüm cihazlarda mükemmel görünüm",
          "PWA desteği - mobil uygulama deneyimi",
          "SEO optimizasyonu - yüksek görünürlük",
          "Yüksek performans ve hızlı yükleme süreleri",
          "Güvenli altyapı ve SSL sertifikası",
          "Kolay yönetilebilir admin paneli"
        ]
      }
    ],
    technologies: [
      "React.js - Modern kullanıcı arayüzleri",
      "Next.js - Server-side rendering ve SEO",
      "Node.js - Güçlü backend altyapısı",
      "MongoDB - Esnek veritabanı çözümü",
      "AWS - Güvenilir hosting hizmeti",
      "Tailwind CSS - Özelleştirilebilir tasarım"
    ],
    process: [
      "İhtiyaç analizi ve planlama",
      "UI/UX tasarımı ve onayı",
      "Frontend geliştirme",
      "Backend entegrasyonu",
      "Testler ve optimizasyon",
      "Deployment ve bakım"
    ]
  },
  {
    title: "Mobil Uygulama ",
    slug: "mobile-development",
    description: "iOS ve Android platformları için native performansta cross-platform mobil uygulamalar geliştiriyoruz.",
    features: [
      {
        title: "Özellikler ve Avantajlar",
        description: "Mobil uygulamalarımızda sunduğumuz temel özellikler:",
        items: [
          "Cross-platform geliştirme - tek kod ile iOS ve Android",
          "Native performans ve görünüm",
          "Offline çalışabilme özelliği",
          "Push bildirim desteği",
          "Cihaz özelliklerine tam erişim",
          "App Store ve Play Store yayınlama"
        ]
      }
    ],
    technologies: [
      "React Native - Cross-platform geliştirme",
      "Firebase - Backend servisleri",
      "Redux - State yönetimi",
      "Native APIs - Cihaz özellikleri",
      "Jest - Test otomasyonu"
    ],
    process: [
      "Proje kapsamı belirleme",
      "UI/UX tasarımı",
      "Uygulama geliştirme",
      "Test ve optimizasyon",
      "Store yayınlama",
      "Bakım ve güncelleme"
    ]
  },
  {
    title: "Blockchain ",
    slug: "blockchain",
    description: "Blockchain teknolojileri ile güvenli, şeffaf ve merkezi olmayan uygulamalar geliştiriyoruz.",
    features: [
      {
        title: "Özellikler ve Avantajlar",
        description: "Blockchain çözümlerimizde sunduğumuz temel özellikler:",
        items: [
          "Akıllı kontrat geliştirme",
          "Token oluşturma ve yönetimi",
          "DeFi uygulamaları",
          "NFT platformları",
          "Blockchain entegrasyonları",
          "Güvenlik auditleri"
        ]
      }
    ],
    technologies: [
      "Ethereum - Ana blockchain platformu",
      "Solidity - Akıllı kontrat geliştirme",
      "Web3.js - Blockchain etkileşimi",
      "IPFS - Merkezi olmayan depolama",
      "Hardhat - Geliştirme ortamı"
    ],
    process: [
      "İhtiyaç analizi",
      "Kontrat geliştirme",
      "Frontend entegrasyonu",
      "Güvenlik testleri",
      "Mainnet deployment",
      "Monitoring ve bakım"
    ]
  },
  {
    title: "Backend ",
    slug: "backend",
    description: "Güçlü, ölçeklenebilir ve güvenli backend sistemleri geliştiriyoruz.",
    features: [
      {
        title: "Özellikler ve Avantajlar",
        description: "Backend sistemlerimizde sunduğumuz temel özellikler:",
        items: [
          "Mikroservis mimarisi",
          "RESTful API tasarımı",
          "Gerçek zamanlı veri işleme",
          "Yüksek performans ve ölçeklenebilirlik",
          "Güvenlik ve yetkilendirme",
          "Otomatik yedekleme sistemleri"
        ]
      }
    ],
    technologies: [
      "Node.js - Ana geliştirme platformu",
      "Express.js - Web framework",
      "MongoDB - NoSQL veritabanı",
      "Redis - Önbellekleme",
      "Docker - Konteynerizasyon",
      "AWS - Cloud altyapısı"
    ],
    process: [
      "Sistem tasarımı",
      "API geliştirme",
      "Veritabanı modelleme",
      "Güvenlik implementasyonu",
      "Load testing",
      "Deployment ve monitoring"
    ]
  }
];

export const whyChooseUs = {
  title: "Neden Bizi Seçmelisiniz?",
  description: "15+ yıllık deneyimimiz ve 80'den fazla başarılı projemizle, teknoloji çözümlerinde güvenilir bir iş ortağıyız.",
  reasons: [
    {
      title: "Modern Teknolojiler",
      description: "En son teknolojileri kullanarak, işinizi geleceğe hazırlıyoruz."
    },
    {
      title: "Özelleştirilmiş Çözümler",
      description: "Her projeye özel yaklaşım ile ihtiyaçlarınıza tam uyumlu çözümler sunuyoruz."
    },
    {
      title: "Sürekli Destek",
      description: "Proje sonrası teknik destek ve bakım hizmetleriyle yanınızdayız."
    }
  ]
};
