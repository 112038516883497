import { Helmet } from 'react-helmet-async';
import { Container } from '../components/Container';
import { FadeIn } from '../components/FadeIn';

export default function Privacy() {
  return (
    <>
      <Helmet>
        <title>KVKK Aydınlatma Metni | Codenra</title>
        <meta name="description" content="Codenra KVKK Aydınlatma Metni. Kişisel verilerin korunması hakkında bilgilendirme." />
      </Helmet>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <div className="max-w-5xl">
            <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
              KVKK Aydınlatma Metni
            </h1>
            <div className="mt-6 text-xl text-neutral-600">
              <p className="mb-8">
                Codenra Teknoloji ve Bilişim Ltd. Şti. olarak kişisel verilerinizin güvenliği hakkında bilgilendirme.
              </p>
            </div>
          </div>

          <div className="mt-24 space-y-12 text-neutral-600">
            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                1. Veri Sorumlusu
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") uyarınca, Codenra Teknoloji ve Bilişim Ltd. Şti. olarak, veri sorumlusu sıfatıyla, kişisel verilerinizi aşağıda açıklanan amaçlar kapsamında; hukuka ve dürüstlük kurallarına uygun bir şekilde işleyebilecek, kaydedebilecek, saklayabilecek, sınıflandırabilecek, güncelleyebilecek ve mevzuatın izin verdiği hallerde ve/veya işlendikleri amaçla sınırlı olarak üçüncü kişilere açıklayabilecek/aktarabileceğiz.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                2. Kişisel Verilerin İşlenme Amacı
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Kişisel verileriniz, hizmetlerimizden faydalanabilmeniz amacıyla, başta 6698 sayılı Kişisel Verilerin Korunması Kanunu ("Kanun") olmak üzere, ilgili mevzuata ve düzenlemelere uygun olarak işlenmektedir.
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Hizmetlerimizin iyileştirilmesi ve geliştirilmesi</li>
                  <li>Müşteri ilişkilerinin yönetimi ve iletişim</li>
                  <li>Yasal yükümlülüklerin yerine getirilmesi</li>
                  <li>İş geliştirme ve analiz faaliyetleri</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                3. Kişisel Verilerin Aktarılması
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Kişisel verileriniz, yukarıda belirtilen amaçların gerçekleştirilmesi doğrultusunda, tedarikçilerimize, kanunen yetkili kamu kurumlarına ve özel kişilere, Kanun'un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                4. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Kişisel verileriniz, her türlü sözlü, yazılı ya da elektronik ortamda, yukarıda yer verilen amaçlar doğrultusunda hizmetlerimizin sunulabilmesi ve bu kapsamda sorumluluklarımızın eksiksiz ve doğru bir şekilde yerine getirilebilmesi için toplanır ve işlenir.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                5. Kişisel Veri Sahibinin Hakları
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Kanun'un 11. maddesi uyarınca veri sahipleri olarak aşağıdaki haklara sahip olduğunuzu bildiririz:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme</li>
                  <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme</li>
                  <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme</li>
                  <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme</li>
                  <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                İletişim
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Yukarıda belirtilen haklarınızı kullanmak için iletisim@codenra.com.tr adresine e-posta gönderebilirsiniz.
                </p>
              </div>
            </section>
          </div>
        </FadeIn>
      </Container>
    </>
  );
} 