import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container } from '../components/Container';
import { FadeIn } from '../components/FadeIn';
import { servicesData } from '../data';

export default function ServiceDetails() {
  const { slug } = useParams();
  const service = servicesData.find(s => s.slug === slug);
  const otherServices = servicesData.filter(s => s.slug !== slug);

  if (!service) {
    return (
      <>
        <Helmet>
          <title>Hizmet Bulunamadı | Codenra</title>
          <meta name="description" content="Aradığınız hizmet detayı bulunamadı. Lütfen diğer hizmetlerimize göz atın." />
        </Helmet>
        <Container className="mt-24 sm:mt-32 lg:mt-40">
          <div>Hizmet bulunamadı.</div>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{service.title} | Codenra</title>
        <meta name="description" content={service.description} />
        <meta name="keywords" content={`${service.title}, Codenra, yazılım, teknoloji, ${service.features?.[0]?.items?.join(', ')}`} />
        <meta property="og:title" content={`${service.title} | Codenra`} />
        <meta property="og:description" content={service.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://codenra.com/services/${slug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${service.title} | Codenra`} />
        <meta name="twitter:description" content={service.description} />
        <link rel="canonical" href={`https://codenra.com/services/${slug}`} />
      </Helmet>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <div className="max-w-5xl">
            <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
              {service.title}
            </h1>
            <div className="mt-6 text-xl text-neutral-600">
              <p className="mb-8">
                {service.description}
              </p>
            </div>
          </div>

          <div className="mt-24 space-y-12 text-neutral-600">
            {service.features && service.features.map((feature, index) => (
              <section key={index}>
                <h2 className="font-display text-2xl font-semibold text-neutral-950">
                  {feature.title}
                </h2>
                <div className="mt-6 space-y-6">
                  <p>{feature.description}</p>
                  {feature.items && (
                    <ul className="list-disc pl-6 space-y-4">
                      {feature.items.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </section>
            ))}

            {service.technologies && (
              <section>
                <h2 className="font-display text-2xl font-semibold text-neutral-950">
                  Kullandığımız Teknolojiler
                </h2>
                <div className="mt-6 space-y-6">
                  <ul className="list-disc pl-6 space-y-4">
                    {service.technologies.map((tech, index) => (
                      <li key={index}>{tech}</li>
                    ))}
                  </ul>
                </div>
              </section>
            )}

            {service.process && (
              <section>
                <h2 className="font-display text-2xl font-semibold text-neutral-950">
                  Geliştirme Süreci
                </h2>
                <div className="mt-6 space-y-6">
                  <ul className="list-decimal pl-6 space-y-4">
                    {service.process.map((step, index) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ul>
                </div>
              </section>
            )}
          </div>

          <div className="mt-32 border-t border-neutral-200 pt-16">
            <h2 className="font-display text-3xl font-medium tracking-tight text-neutral-950">
              Diğer Hizmetlerimiz
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {otherServices.map((otherService) => (
                <Link
                  key={otherService.slug}
                  to={`/services/${otherService.slug}`}
                  className="group relative overflow-hidden rounded-3xl bg-neutral-100 px-6 pb-16 pt-10 transition-colors hover:bg-neutral-200"
                >
                  <h3 className="font-display text-xl font-semibold text-neutral-950">
                    {otherService.title}
                  </h3>
                  <p className="mt-4 text-sm text-neutral-700 line-clamp-3">
                    {otherService.description}
                  </p>
                  <div className="absolute bottom-6 left-6 flex items-center text-sm font-semibold text-neutral-950">
                    Detaylı Bilgi
                    <svg
                      className="ml-3 h-4 w-4 transition-transform group-hover:translate-x-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </FadeIn>
      </Container>
    </>
  );
} 