import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { ContactSection } from "../components/ContactSection";
import { Container } from "../components/Container";
import { FadeIn, FadeInStagger } from "../components/FadeIn";
import { List, ListItem } from "../components/List";
import { SectionIntro } from "../components/SectionIntro";
import { StylizedImage } from "../components/StylizedImage";
import { Testimonial } from "../components/Testimonial";
import imageLaptop from "../images/laptop.jpg";

import { clients, completedProjects, servicesData } from "../data";

export function Clients() {
  return (
    <div className="mt-24 rounded-4xl bg-neutral-950 py-20 sm:mt-32 sm:py-32 lg:mt-56">
      <Container>
        <FadeIn className="flex items-center gap-x-8">
          <h2 className="text-center font-display text-sm font-semibold tracking-wider text-white sm:text-left">
            Hizmetlerimizden <b>10.000</b>’den fazla kullanıcı faydalandı ve{" "}
            <b>80</b>’den fazla projeyi başarıyla tamamladık
          </h2>
          <div className="h-px flex-auto bg-neutral-800" />
        </FadeIn>
        <FadeInStagger faster>
          <ul
            role="list"
            className="mt-10 grid grid-cols-3 gap-x-8 gap-y-10 lg:grid-cols-6"
          >
            {clients.map((client, index) => (
              <li
                key={index}
                className="flex flex-row items-center justify-center"
              >
                <FadeIn>
                  <img
                    src={client.image}
                    alt={client.name}
                    className="h-20 w-20 object-contain"
                  />
                </FadeIn>
              </li>
            ))}
          </ul>
        </FadeInStagger>
      </Container>
    </div>
  );
}

function CaseStudies({ caseStudies }) {
  return (
    <>
      <SectionIntro
        title={`En Son Teknolojiyle Geliştirilmiş Tasarım Harikası Çözümler`}
        className="mt-24 sm:mt-32 lg:mt-40"
      >
        <p>
          Müşterilerimize en güncel teknoloji ile geliştirilmiş,{" "}
          <b className="text-black">tasarım harikası</b> olarak tanımladığımız
          çözümler sunuyoruz
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <FadeInStagger className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {caseStudies.map((caseStudy) => (
            <FadeIn key={caseStudy.href} className="flex">
              <article className="relative bg-black flex w-full flex-col rounded-3xl p-6 ring-1 ring-neutral-950/5 transition hover:bg-stone-900 sm:p-8">
                <h3>
                  <Link to={caseStudy.href}>
                    <span className="absolute inset-0 rounded-3xl" />
                    <img
                      src={caseStudy.logo}
                      alt={caseStudy.client}
                      className="h-10"
                    />
                  </Link>
                </h3>
                <p className="mt-6 flex gap-x-2 text-sm text-neutral-950">
                  <time
                    dateTime={caseStudy.date.split("-")[0]}
                    className="font-semibold"
                  >
                    {caseStudy.date.split("-")[0]}
                  </time>
                </p>
                <p className="mt-6 font-display text-2xl font-semibold text-neutral-50">
                  {caseStudy.title}
                </p>
                <p className="mt-4 text-base text-gray-300">
                  {caseStudy.description}
                </p>
              </article>
            </FadeIn>
          ))}
        </FadeInStagger>
      </Container>
    </>
  );
}

function Services() {
  return (
    <>
      <SectionIntro
        eyebrow="Hizmetlerimiz"
        title="Modern Teknolojilerle İş Süreçlerinizi Dönüştürüyoruz"
        className="mt-24 sm:mt-32 lg:mt-40"
      >
        <p>
          Web uygulamalarından mobil çözümlere, blockchain teknolojilerinden özelleştirilmiş backend sistemlere kadar geniş bir yelpazede hizmet sunuyoruz.
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <div className="lg:flex lg:items-center lg:justify-end">
          <div className="flex justify-center lg:w-1/2 lg:justify-end lg:pr-12">
            <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
              <StylizedImage
                src={imageLaptop}
                sizes="(min-width: 1024px) 41rem, 31rem"
                className="justify-center lg:justify-end"
              />
            </FadeIn>
          </div>
          <List className="mt-16 lg:mt-0 lg:w-1/2 lg:min-w-[33rem] lg:pl-4">
            {servicesData.map((service, index) => (
              <ListItem key={index} title={service.title}>
                {service.description}
                <div className="mt-2">
                  <Link
                    to={`/services/${service.slug}`}
                    className="text-sm font-semibold text-neutral-950 transition hover:text-neutral-700"
                  >
                    Detaylı Bilgi →
                  </Link>
                </div>
              </ListItem>
            ))}
          </List>
        </div>
        <div className="mt-10 flex justify-end">
          <Link
            to="/services"
            className="group inline-flex items-center gap-2 text-base font-semibold text-neutral-950 transition hover:text-neutral-700"
          >
            Tüm Hizmetlerimiz
            <svg
              className="h-6 w-6 transform transition-transform group-hover:translate-x-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </Link>
        </div>
      </Container>
    </>
  );
}

export default function Home() {

  const titles = [
    "Yaratıcı Kodlarla Geleceği İnşa Edin.",
    "Dijital Çözümlerle İşinizi Büyütün.",
    "Teknolojiyle Fikirlerinizi Gerçekleştirin.",
    "Yazılımla İş Süreçlerinizi Güçlendirin.",
    "İnovatif Kodlarla Yeni Ufuklar Açın.",
    "Teknolojiyi İşinize Uygun Hale Getirin.",
    "Hayallerinizi Dijital Dünyada Canlandırın.",
    "Geleceği Şekillendiren Çözümler Sunun.",
    "Akıllı Yazılımlarla İşinizi Kolaylaştırın.",
    "Yazılımın Gücüyle Sınırları Aşın."
  ];

  const randomTitle = titles[Math.floor(Math.random() * titles.length)];
  return (
    <>
      <Helmet>
        <title>Codenra | Modern Teknoloji Çözümleri</title>
        <meta name="description" content="Web uygulamalarından mobil çözümlere, blockchain teknolojilerinden özelleştirilmiş backend sistemlere kadar geniş bir yelpazede teknoloji çözümleri sunuyoruz." />
      </Helmet>
      
      <Container className="mt-24 sm:mt-32 md:mt-56">
        <FadeIn className="max-w-3xl">
          <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
            {randomTitle}

          </h1>
          <p className="mt-6 text-xl text-neutral-600">
            İster yeni bir web sitesi veya mobil uygulama geliştirmek isteyin,
            ister mevcut sistemlerinizi optimize etmek isteyin, size en uygun
            çözümleri sunmak için buradayız.
          </p>
        </FadeIn>
      </Container>

      <Clients />

      <CaseStudies caseStudies={completedProjects} />

      {/*  <Testimonial
        className="mt-24 sm:mt-32 lg:mt-40"
        client={{ name: "Phobia", logo: logoPhobiaDark }}
      >
        The team at Studio went above and beyond with our onboarding, even
        finding a way to access the user’s microphone without triggering one of
        those annoying permission dialogs.
      </Testimonial> */}

      <Services />

      <ContactSection />
    </>
  );
}
