import { Helmet } from 'react-helmet-async';
import { Container } from "../components/Container";
import { FadeIn } from "../components/FadeIn";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>Sayfa Bulunamadı | Codenra</title>
        <meta name="description" content="Aradığınız sayfa bulunamadı. Ana sayfaya dönmek için tıklayın." />
      </Helmet>

      <Container className="flex h-full items-center pt-24 sm:pt-32 lg:pt-40">
        <FadeIn className="flex max-w-xl flex-col items-center text-center">
          <p className="font-display text-4xl font-semibold text-neutral-950 sm:text-5xl">
            404
          </p>
          <h1 className="mt-4 font-display text-2xl font-semibold text-neutral-950">
            Sayfa bulunamadı
          </h1>
          <p className="mt-2 text-sm text-neutral-600">
            Ulaşmaya çalıştığınız sayfa silinmiş veya taşınmış olabilir. Lütfen diğer sayfalara göz atın.
          </p>
          <Link
            to="/"
            className="mt-4 text-sm font-semibold text-neutral-950 transition hover:text-neutral-700"
          >
              Anasayfa
          </Link>
        </FadeIn>
      </Container>
    </>
  );
}
