import { Helmet } from 'react-helmet-async';
import { Container } from '../components/Container';
import { FadeIn } from '../components/FadeIn';

export default function DataPrivacy() {
  return (
    <>
      <Helmet>
        <title>Gizlilik Politikası | Codenra</title>
        <meta name="description" content="Codenra gizlilik politikası. Verilerinizin nasıl toplandığı, kullanıldığı ve korunduğu hakkında bilgi." />
      </Helmet>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <div className="max-w-5xl">
            <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
              Gizlilik Politikası
            </h1>
            <div className="mt-6 text-xl text-neutral-600">
              <p className="mb-8">
                Codenra Teknoloji ve Bilişim Ltd. Şti. olarak kullanıcı verilerinizin güvenliği ve gizliliği konusundaki taahhütlerimiz.
              </p>
            </div>
          </div>

          <div className="mt-24 space-y-12 text-neutral-600">
            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                1. Veri Güvenliği
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Kullanıcılarımızın verilerini korumak için endüstri standardı güvenlik önlemleri kullanıyoruz. Verileriniz şifreleme teknolojileri ile korunmakta ve düzenli olarak güvenlik değerlendirmelerinden geçirilmektedir.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                2. Veri Toplama ve Kullanım
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Hizmetlerimizi sunmak ve geliştirmek için topladığımız veriler:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Kullanıcı tercihleri ve ayarları</li>
                  <li>Cihaz ve tarayıcı bilgileri</li>
                  <li>Kullanım istatistikleri ve analitik veriler</li>
                  <li>İletişim bilgileri (isteğe bağlı)</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                3. Üçüncü Taraf Hizmetler
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Hizmet kalitemizi artırmak için kullandığımız üçüncü taraf hizmetler ve bu hizmetlerin veri kullanım politikaları hakkında bilgilendirme.
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Analitik hizmetleri</li>
                  <li>Hosting servisleri</li>
                  <li>İletişim araçları</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                4. Kullanıcı Hakları
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Kullanıcılarımızın verileri üzerindeki hakları:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Verilerinize erişim talep etme</li>
                  <li>Verilerinizin düzeltilmesini isteme</li>
                  <li>Verilerinizin silinmesini talep etme</li>
                  <li>Veri işleme faaliyetlerini kısıtlama</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                İletişim
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Gizlilik politikamız ile ilgili sorularınız için iletisim@codenra.com.tr adresine e-posta gönderebilirsiniz.
                </p>
              </div>
            </section>
          </div>
        </FadeIn>
      </Container>
    </>
  );
} 