import { Helmet } from 'react-helmet-async';
import { Container } from '../components/Container';
import { FadeIn } from '../components/FadeIn';

export default function Refund() {
  return (
    <>
      <Helmet>
        <title>İptal ve İade Koşulları | Codenra</title>
        <meta name="description" content="Codenra iptal ve iade koşulları. Hizmetlerimizle ilgili iptal ve iade süreçleri hakkında bilgi." />
      </Helmet>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <div className="max-w-5xl">
            <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
              İptal ve İade Koşulları
            </h1>
            <div className="mt-6 text-xl text-neutral-600">
              <p className="mb-8">
                Codenra Teknoloji ve Bilişim Ltd. Şti. olarak sunduğumuz hizmetlere ilişkin iptal ve iade koşullarımız.
              </p>
            </div>
          </div>

          <div className="mt-24 space-y-12 text-neutral-600">
            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                1. Genel Hükümler
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Hizmetlerimize ilişkin iptal ve iade süreçleri, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği kapsamında yürütülmektedir.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                2. İptal Koşulları
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Hizmet iptal süreçlerimiz:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Proje başlangıcından önce yapılan iptaller</li>
                  <li>Proje sürecinde yapılan iptaller</li>
                  <li>Özel durumlar ve mücbir sebepler</li>
                  <li>İptal süreci ve gerekli belgeler</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                3. İade Koşulları
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  İade süreçlerimiz aşağıdaki durumları kapsar:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Hizmet kalitesi garantisi</li>
                  <li>Teknik sorunlar ve hatalar</li>
                  <li>Proje teslim süreleri</li>
                  <li>Müşteri memnuniyeti garantisi</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                4. İade Süreci
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  İade talebiniz onaylandığında:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Ödeme iadesi 14 iş günü içinde yapılır</li>
                  <li>İade ödemesi, orijinal ödeme yöntemiyle gerçekleştirilir</li>
                  <li>Kısmi iade durumları değerlendirilir</li>
                  <li>İade sürecinde gerekli belgeler talep edilir</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                İletişim
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  İptal ve iade talepleriniz için iletisim@codenra.com.tr adresine e-posta gönderebilirsiniz.
                </p>
              </div>
            </section>
          </div>
        </FadeIn>
      </Container>
    </>
  );
} 