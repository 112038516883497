import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { PageIntro } from "../components/PageIntro";
import { FadeIn } from "../components/FadeIn";
import { Container } from "../components/Container";
import { MDXComponents } from "../components/MDXComponents";
import { GrayscaleTransitionImage } from "../components/GrayscaleTransitionImage";
import { ContactSection } from "../components/ContactSection";
import { TagList, TagListItem } from "../components/TagList";
import { Blockquote } from "../components/Blockquote";
import { StatList, StatListItem } from "../components/StatList";
import { PageLinks } from "../components/PageLinks";
import { completedProjects } from "../data";
import { Button } from "../components/Button";

function WorkDetails() {
  const { work_name } = useParams();

  const [caseStudy, setCaseStudy] = useState(null);
  const moreCaseStudies = useMemo(
    () =>
      completedProjects
        .filter((item) => item.work_name !== work_name)
        .slice(0, 4),
    [work_name]
  );

  useEffect(() => {
    if (
      work_name == "daisy-wheel" ||
      work_name == "iatse891-heat-stress" ||
      work_name == "glitter-magazine" ||
      work_name == "qa-platform" ||
      work_name == "demand" ||
      work_name == "apifiz"
    ) {
      const study = completedProjects.find(
        (element) => element.work_name == work_name
      );

      setCaseStudy(study);
    }
  }, [work_name]);

  if (caseStudy)
    return (
      <>
        <Helmet>
          <title>{caseStudy.title} | Codenra</title>
          <meta name="description" content={caseStudy.description} />
          <meta name="keywords" content={`${caseStudy.title}, ${caseStudy.client}, Codenra, ${caseStudy.service}`} />
          <meta property="og:title" content={`${caseStudy.title} | Codenra`} />
          <meta property="og:description" content={caseStudy.description} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`https://codenra.com/works/${work_name}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${caseStudy.title} | Codenra`} />
          <meta name="twitter:description" content={caseStudy.description} />
          <link rel="canonical" href={`https://codenra.com/works/${work_name}`} />
        </Helmet>

        <article className="mt-24 sm:mt-32 lg:mt-40">
          <header>
            <PageIntro eyebrow="" title={caseStudy.title} centered>
              <p>{caseStudy.description}</p>
            </PageIntro>

            <div className="flex justify-center gap-8 mt-5">
              {caseStudy?.websiteLink ? (
                <a target="_blank" href={caseStudy.websiteLink}>
                  <Button>Siteye Git</Button>
                </a>
              ) : null}

              {caseStudy?.appStoreUrl ? (
                <a target="_blank" href={caseStudy.appStoreUrl}>
                  <Button>App Store</Button>
                </a>
              ) : null}

              {caseStudy?.playStoreUrl ? (
                <a target="_blank" href={caseStudy.playStoreUrl}>
                  <Button>Play Store</Button>
                </a>
              ) : null}
            </div>

            <FadeIn>
              <div className="mt-24 border-t border-neutral-200 bg-white/50 sm:mt-32 lg:mt-40">
                <Container>
                  <div className="mx-auto max-w-5xl">
                    <dl className="-mx-6 grid grid-cols-1 text-sm text-neutral-950 sm:mx-0 sm:grid-cols-2">
                      <div className="border-t border-neutral-200 px-6 py-4 first:border-t-0 sm:border-l sm:border-t-0">
                        <dt className="font-semibold">Müşteri</dt>
                        <dd>{caseStudy.client}</dd>
                      </div>

                      {/*<div className="border-t border-neutral-200 px-6 py-4 first:border-t-0 sm:border-l sm:border-t-0">
                        <dt className="font-semibold">Year</dt>
                        <dd>
                          <time dateTime={caseStudy.date.split("-")[0]}>
                            {caseStudy.date.split("-")[0]}
                          </time>
                        </dd>
                      </div>*/}

                      <div className="border-t border-neutral-200 px-6 py-4 first:border-t-0 sm:border-l sm:border-t-0">
                        <dt className="font-semibold">Tür</dt>
                        <dd>{caseStudy.service}</dd>
                      </div>
                    </dl>
                  </div>
                </Container>
              </div>

              <FadeIn>
                <div className="border-y border-neutral-200 bg-neutral-100">
                  <div className="-my-px mx-auto max-w-[76rem] bg-neutral-200">
                    <GrayscaleTransitionImage
                      src={caseStudy.image}
                      quality={90}
                      className="w-full"
                      sizes="(min-width: 1216px) 76rem, 100vw"
                      priority
                    />
                  </div>
                </div>
              </FadeIn>
            </FadeIn>
          </header>

          <Container className="mt-24 sm:mt-32 lg:mt-40">
            <FadeIn>
              <MDXComponents.wrapper>
                <h1 className="mt-6 block max-w-2xl font-display text-2xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-2xl mb-5">
                  Detaylar
                </h1>

                {caseStudy.overview.split("\n").map((paragraph, index) => (
                  <p key={index} className="text-xl mb-5">
                    {paragraph}
                  </p>
                ))}

                {/* 
                <h1 className="mt-12 block max-w-2xl font-display text-2xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-2xl mb-5">
                  What we did
                </h1>

                <TagList>
                  <TagListItem>Frontend (Next.js)</TagListItem>
                  <TagListItem>Custom CMS</TagListItem>
                  <TagListItem>SEO</TagListItem>
                  <TagListItem>Infrastructure</TagListItem>
                </TagList>
                

                <Blockquote
                  author={{ name: "Debra Fiscal", role: "CEO of FamilyFund" }}
                  image={{ src: imageDebraFiscal }}
                  className="mt-24"
                >
                  Working with Studio, we felt more like a partner than a
                  customer. They really resonated with our mission to change the
                  way people convince their parents to cash out their pensions.
                </Blockquote>
                */}
              </MDXComponents.wrapper>

              {/*
              <StatList className="mt-24">
                <StatListItem value="25%" label="Less traffic" />
                <StatListItem value="10x" label="Page load times" />
                <StatListItem value="15%" label="Higher infra costs" />
                <StatListItem value="$1.2M" label="Legal fees" />
              </StatList>
               */}
            </FadeIn>
          </Container>
        </article>

        {moreCaseStudies.length > 0 && (
          <PageLinks
            className="mt-24 sm:mt-32 lg:mt-40"
            title="Diğer Çalışmalarımız"
            pages={moreCaseStudies}
          />
        )}

        <ContactSection />
      </>
    );
}

export default WorkDetails;
