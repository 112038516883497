import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { Container } from "../components/Container";
import { FadeIn } from "../components/FadeIn";
import { servicesData } from "../data";

export default function Services() {
  return (
    <>
      <Helmet>
        <title>Hizmetlerimiz | Codenra</title>
        <meta name="description" content="Yazılım geliştirme, mobil uygulama, web uygulamaları, blockchain ve backend sistemleri alanlarında profesyonel hizmetler sunuyoruz." />
      </Helmet>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
            Hizmetlerimiz
          </h1>
          <p className="mt-6 text-xl text-neutral-600">
            Teknoloji çözümlerimizle işinizi bir adım öteye taşıyoruz.
          </p>
        </FadeIn>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
          {servicesData.map((service, index) => (
            <FadeIn key={index}>
              <div className="flex h-full flex-col justify-between bg-neutral-950 p-10 rounded-3xl">
                <div>
                  <h2 className="font-display text-2xl font-semibold text-white">
                    {service.title}
                  </h2>
                  <p className="mt-4 text-base text-neutral-300">
                    {service.description}
                  </p>
                  {service.features && (
                    <ul className="mt-8 space-y-3 text-sm text-neutral-300">
                      {service.features[0].items.slice(0, 3).map((item, idx) => (
                        <li key={idx} className="flex items-center">
                          <svg
                            className="h-5 w-5 flex-none fill-current text-white"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            />
                          </svg>
                          <span className="ml-3">{item}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="mt-8">
                  <Link
                    to={`/services/${service.slug}`}
                    className="inline-flex items-center text-sm font-semibold text-white hover:text-neutral-200"
                  >
                    Detaylı Bilgi
                    <svg
                      className="ml-2 h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </FadeIn>
          ))}
        </div>
      </Container>
    </>
  );
} 