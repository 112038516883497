import { Link } from "react-router-dom";
import { useState } from "react";

import { Container } from "../components/Container";
import { FadeIn } from "../components/FadeIn";
import { Logo } from "../components/Logo";
import { socialMediaProfiles } from "../components/SocialMedia";
import { Office } from "./Offices";
import { Button } from "./Button";
import { servicesData } from "../data";

const navigation = [
  {
    title: "Çalışmalar",
    links: [
      { title: "Apifiz", to: "/works/apifiz" },
      { title: "Demand", to: "/works/demand" },
      { title: "Q/A Platform", to: "/works/qa-platform" },
      {
        title: (
          <>
            Tümünü gör <span aria-hidden="true">&rarr;</span>
          </>
        ),
        to: "/works",
      },
    ],
  },
  {
    title: "Kurumsal",
    links: [
      { title: "Hakkımızda", to: "/corporate" },
      { title: "Geliştirme Süreci", to: "/process" },
      { title: "İletişim", to: "/contact" },
      { title: "Hizmetlerimiz", to: "/services" },
    ],
  },
  {
    title: "Sosyal Medya",
    links: socialMediaProfiles,
  },
  {
    title: "Hizmetlerimiz",
    links: servicesData.map(service => ({
      title: service.title,
      to: `/services/${service.slug}`,
    })),
  },
];

function Navigation() {
  return (
    <nav>
      <ul role="list" className="grid grid-cols-2 gap-8 sm:grid-cols-4">
        {navigation.map((section, sectionIndex) => (
          <li key={sectionIndex}>
            <div className="font-display text-sm font-semibold tracking-wider text-neutral-950">
              {section.title}
            </div>
            <ul role="list" className="mt-4 text-sm text-neutral-700">
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex} className="mt-4">
                  <Link
                    to={link.to}
                    className="transition hover:text-neutral-950"
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
}

function ArrowIcon(props) {
  return (
    <svg viewBox="0 0 16 6" aria-hidden="true" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3 10 .5v2H0v1h10v2L16 3Z"
      />
    </svg>
  );
}

function NewsletterForm() {
  return (
    <div>
      <h2 className="font-display text-sm font-semibold tracking-wider text-neutral-950">
        İletişim{" "}
      </h2>

      <address className="text-neutral-900 text-sm not-italic">
        Feyzullah Mah. Şehit Hikmet Alp Cad. No:8/28 <br /> Maltepe / İstanbul / Türkiye
      </address>

      <div className="my-2 mt-3">
        <a
          className="text-black font-semibold text-sm"
          href="tel:+905547997414"
        >
          +90 (554) 799 74 14
        </a>
      </div>

      <div>
        <a
          className="text-black font-semibold text-sm"
          href="mailto:iletisim@codenra.com.tr"
        >
          iletisim@codenra.com.tr
        </a>
      </div>

      <div className="mt-3">
        <Link to="/contact">
          <Button invert={false}>İletişime Geçin</Button>
        </Link>
      </div>
    </div>
  );
}

function CopyButton({ text, label }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Kopyalama başarısız oldu:', err);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className="group relative inline-flex items-center hover:text-neutral-950"
    >
      {label}
      <span className="absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-neutral-950 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
        {copied ? 'Kopyalandı!' : 'Kopyala'}
      </span>
    </button>
  );
}

function ScrollToTopLink() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className="hover:text-neutral-950 text-sm cursor-pointer"
    >
      Yukarı Git ↑
    </button>
  );
}

export function Footer() {
  return (
    <Container as="footer" className="mt-24 w-full sm:mt-32 lg:mt-40">
      <FadeIn>
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
          <Navigation />
          <div className="flex lg:justify-end">
            <NewsletterForm />
          </div>
        </div>

        <div className="mb-20 mt-24 border-t border-neutral-950/10 pt-12">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col text-sm text-neutral-600">
                <p className="text-center sm:text-left">
                  Tüm hakları saklıdır. Bu web sitesi, Codenra Teknoloji ve Bilişim Ltd. Şti. tarafından işletilmekte olup, 5846 sayılı Fikir ve Sanat Eserleri Kanunu kapsamında korunmaktadır. Kullanıcı deneyimini geliştirmek ve güvenliğinizi sağlamak amacıyla çerezler kullandığımızı hatırlatmak isteriz.
                </p>
                <div className="mt-4 flex flex-row justify-center sm:justify-start gap-4">
                  <CopyButton text="2111226900" label="Vergi No: 2111226900" />
                  <CopyButton text="0123456789123456" label="Mersis No: 0123456789123456" />
                </div>
              </div>

              <div className="flex flex-wrap justify-center sm:justify-start gap-4 text-sm">
                <Link to="/privacy" className="hover:text-neutral-950">
                  KVKK Aydınlatma Metni
                </Link>
                <Link to="/terms" className="hover:text-neutral-950">
                  Kullanım Koşulları
                </Link>
                <Link to="/cookies" className="hover:text-neutral-950">
                  Çerez Politikası
                </Link>
                <Link to="/data-privacy" className="hover:text-neutral-950">
                  Gizlilik Politikası
                </Link>
                <Link to="/refund" className="hover:text-neutral-950">
                  İptal ve İade Koşulları
                </Link>
                <span className="text-neutral-300">|</span>
                <ScrollToTopLink />
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    </Container>
  );
}
