import { Helmet } from 'react-helmet-async';
import { Container } from '../components/Container';
import { FadeIn } from '../components/FadeIn';

export default function Cookies() {
  return (
    <>
      <Helmet>
        <title>Çerez Politikası | Codenra</title>
        <meta name="description" content="Codenra çerez politikası. Web sitemizde kullanılan çerezler hakkında detaylı bilgi." />
      </Helmet>

      <Container className="mt-24 sm:mt-32 lg:mt-40">
        <FadeIn>
          <div className="max-w-5xl">
            <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
              Çerez Politikası
            </h1>
            <div className="mt-6 text-xl text-neutral-600">
              <p className="mb-8">
                Codenra Teknoloji ve Bilişim Ltd. Şti. web sitesinde kullanılan çerezler hakkında bilgilendirme.
              </p>
            </div>
          </div>

          <div className="mt-24 space-y-12 text-neutral-600">
            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                1. Çerez (Cookie) Nedir?
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Çerezler, web sitemizi ziyaret ettiğinizde tarayıcınız aracılığıyla cihazınıza depolanan küçük metin dosyalarıdır. Bu dosyalar, web sitemizin düzgün çalışması ve sizin deneyiminizi iyileştirmek için gerekli bilgileri saklar.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                2. Hangi Çerezleri Kullanıyoruz?
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Web sitemizde aşağıdaki çerez türlerini kullanmaktayız:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>
                    <strong>Zorunlu Çerezler:</strong> Sitenin çalışması için gerekli olan temel çerezler
                  </li>
                  <li>
                    <strong>Performans Çerezleri:</strong> Sitemizin performansını analiz eden ve iyileştiren çerezler
                  </li>
                  <li>
                    <strong>İşlevsellik Çerezleri:</strong> Tercihlerinizi hatırlamamızı sağlayan çerezler
                  </li>
                  <li>
                    <strong>Analitik Çerezler:</strong> Ziyaretçi davranışlarını analiz eden çerezler
                  </li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                3. Çerezlerin Kullanım Amacı
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Çerezleri aşağıdaki amaçlar için kullanmaktayız:
                </p>
                <ul className="list-disc pl-6 space-y-4">
                  <li>Web sitemizin düzgün çalışmasını sağlamak</li>
                  <li>Kullanıcı deneyimini iyileştirmek</li>
                  <li>Site kullanımı hakkında istatistiki bilgiler toplamak</li>
                  <li>Güvenliği sağlamak</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                4. Çerezleri Nasıl Kontrol Edebilirsiniz?
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Tarayıcınızın ayarlarından çerezleri kontrol edebilir veya silebilirsiniz. Ancak bazı çerezleri devre dışı bırakmanın, web sitemizin bazı özelliklerinin çalışmamasına neden olabileceğini unutmayın.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                5. Değişiklikler
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Bu çerez politikasını herhangi bir zamanda değiştirme hakkını saklı tutarız. Değişiklikler web sitemizde yayınlandığı anda yürürlüğe girer.
                </p>
              </div>
            </section>

            <section>
              <h2 className="font-display text-2xl font-semibold text-neutral-950">
                İletişim
              </h2>
              <div className="mt-6 space-y-6">
                <p>
                  Çerez politikamız ile ilgili sorularınız için iletisim@codenra.com.tr adresine e-posta gönderebilirsiniz.
                </p>
              </div>
            </section>
          </div>
        </FadeIn>
      </Container>
    </>
  );
} 